<template>
    <div class="layout">
        <jx-header></jx-header>
        <jx-main></jx-main>
        <jx-footer></jx-footer>
    </div>
</template>
<script>
// 母版页
import jxHeader from './header.vue';
import jxMain from './main.vue';
import jxFooter from './footer.vue';

export default {
    name: 'layout',
    components: {
        jxHeader,
        jxMain,
        jxFooter
    },
    data() {
        return {

        };
    }
};
</script>
<style lang="scss" scoped>
.layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    .main {
        flex: 1;
    }
}
</style>
