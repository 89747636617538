<template>
    <div class="jxmain">
        <transition
            name="el-zoom-in-center"
            mode="out-in"
        >
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
        </transition>
        <transition
            name="el-zoom-in-center"
            mode="out-in"
        >
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </transition>
    </div>
</template>
<script>
// 主体
export default {
    name: 'jxmain',
    data() {
        return {

        };
    }
};
</script>
<style lang="scss" scoped>
.jxmain {
    margin-top: 140px;
}
</style>
