<template>
    <div class="jxheader">
        <div
            class="head-content"
            v-show="!$route.meta.showHeader"
        >
            <div class="con">
                <div class="con-logo">
                    <img src="../images/logo.png" />
                </div>
                <div class="con-mue">
                    <p>车型：</p>
                    <!-- <router-link
                        to="/choiceCar/1"
                        active-class="mue-ine"
                        @click="test"
          >-->
                    <template v-if="car.car && car.car.length">
                        <p
                            v-for="item in car.car"
                            :key="item.Id"
                            :class="{'mue-ine':item.Id === car.selectId}"
                            @click="carTypeClick(item.Id)"
                        >{{item.Name}}</p>
                    </template>
                    <div
                        class="car-more"
                        @click="showCar"
                    >
                        查看更多
                    </div>
                    <ul
                        class="car-list"
                        v-show="isMore"
                    >
                        <li
                            v-for="item in car.car"
                            :key="item.Id"
                            :class="{'mue-ine':item.Id === car.selectId}"
                            @click="carTypeClick(item.Id)"
                        >{{item.Name}}</li>
                    </ul>
                    <!-- </router-link> -->
                    <!-- <router-link
                        to="/choiceCar/2"
                        active-class="mue-ine"
          >-->
                    <!-- </router-link> -->
                    <!-- <router-link
                        to="/choiceCar/3"
                        active-class="mue-ine"
          >-->
                    <!-- </router-link>
                    <router-link
                        to="/choiceCar/4"
                        active-class="mue-ine"
          >-->
                    <!-- </router-link>  -->
                </div>

                <div class="con-user">
                    <p
                        class="user-name"
                        v-show="currentPhone"
                    >{{currentUserName || currentPhone}}</p>
                    <p
                        class="user-btn01"
                        @click="openVip"
                        v-show="!isVip"
                    >
                        <img src="../images/aa.png" />激活成为VIP
                    </p>
                    <p
                        class="user-btn01 ptText"
                        v-show="isVip"
                    >
                        <img src="../images/aa.png" />
                        {{vipDate === '永久会员' ? vipDate : `${vipDate}到期`}}
                    </p>
                    <p
                        class="user-rgt"
                        v-show="!currentPhone"
                        @click="getSign()"
                    >注册</p>
                    <p
                        class="user-log"
                        v-show="!currentPhone"
                        @click="getLog()"
                    >登录</p>
                    <template v-if="currentPhone">
                        <p
                            class="user-log"
                            slot="reference"
                            @click="logoutBtn"
                        >退出</p>
                    </template>
                </div>
            </div>
        </div>
        <div
            class="home"
            v-show="!$route.meta.showHeader"
        >
            <div class="menu">
                <router-link
                    to="/home"
                    active-class="menu-lin"
                >
                    <p>首页</p>
                </router-link>
                <router-link
                    to="/choiceCar"
                    active-class="menu-lin"
                >
                    <p>普通练习</p>
                </router-link>
                <router-link
                    to="/activeVip"
                    active-class="menu-lin"
                >
                    <p>VIP</p>
                </router-link>
                <router-link
                    to="/article"
                    active-class="menu-lin"
                >
                    <p>文章中心</p>
                </router-link>
                <router-link
                    to="/video"
                    active-class="menu-lin"
                >
                    <p>学车视频</p>
                </router-link>
                <router-link
                    to="/hotline"
                    active-class="menu-lin"
                >
                    <p>客服热线</p>
                </router-link>
                <router-link
                    to="/help"
                    active-class="menu-lin"
                >
                    <p>帮助中心</p>
                </router-link>
            </div>
        </div>
        <!--注册弹窗-->
        <div
            class="popup"
            v-show="showSign"
        >
            <div class="active-popup animated fadeIn">
                <div class="login-popup-con">
                    <div class="login-close">
                        <span @click="closeSign()">X</span>
                    </div>
                    <div class="login-logImg flex flex-jc">
                        <img src="../images/logo.png" />
                        <span>用户注册</span>
                    </div>
                    <div class="login-form">
                        <div class="login-form-inp01">
                            <input
                                type="tel"
                                v-model.trim="regModel.phone"
                                placeholder="请输入手机号码"
                            />
                        </div>
                        <div class="login-form-inp01 login-inp-code">
                            <input
                                type="text"
                                class="input code"
                                name="code"
                                v-model.trim="regModel.code"
                                placeholder="请输入短信验证码"
                            />
                            <div
                                @click="getCode(formData)"
                                class="code-btn"
                                :disabled="!show"
                            >
                                <span v-show="show">发送验证码</span>
                                <span
                                    v-show="!show"
                                    class="count"
                                >{{count}} s</span>
                            </div>
                        </div>
                        <div class="login-form-inp01">
                            <input
                                type="password"
                                v-model="regModel.pwd1"
                                placeholder="设置6-20位登录密码"
                            />
                        </div>
                        <div class="login-form-inp01">
                            <input
                                type="password"
                                v-model="regModel.pwd2"
                                placeholder="再次输入登录密码"
                            />
                        </div>
                        <div
                            class="login-form-deal flex"
                            @click="deal()"
                        >
                            <span :class="sleDeal==false?'log-deal-sle':'log-deal-lin'"></span>
                            <span class="login-pop-ctxt01">
                                勾选同意
                                <font
                                    color="#ff6637"
                                    style="padding-left: 10px;"
                                    @click.stop="goAgreement"
                                >《用户服务协议》</font>
                                <font
                                    color="#ff6637"
                                    style="padding-left: 10px;"
                                    @click.stop="goPrivacy"
                                >《用户隐私政策》</font>
                            </span>
                        </div>
                        <div
                            class="login-popup-btn"
                            style="cursor:pointer;"
                            @click="reg();"
                        >注册</div>
                    </div>
                    <div class="login-popup-txt">
                        已有账户，
                        <font
                            color="#ff6637"
                            @click="getLog()"
                        >去登录 ></font>
                    </div>
                </div>
            </div>
        </div>
        <!--登录弹窗-->
        <div
            class="popup"
            v-show="showLog || token.showLogin"
        >
            <div
                class="active-popup animated fadeIn"
                style="min-height: 520px;"
            >
                <div class="login-popup-con">
                    <div
                        class="login-close"
                        @click="closeLog()"
                    >
                        <span>X</span>
                    </div>
                    <div class="login-logImg flex flex-jc">
                        <img src="../images/logo.png" />
                        <span>用户登录</span>
                    </div>
                    <div class="login-form">
                        <div class="login-form-inp01">
                            <input
                                type="tel"
                                v-model="loginModel.phone"
                                placeholder="输入用户名或手机号码"
                            />
                        </div>
                        <div class="login-form-inp01">
                            <input
                                type="password"
                                v-model="loginModel.pwd"
                                placeholder="输入登录密码"
                            />
                        </div>
                        <div
                            class="login-popup-btn"
                            @click="login()"
                            style="margin-top: 20px;cursor:pointer;"
                        >登录</div>
                        <div class="login-form-deal flex">
                            <p @click="deal()">
                                <span :class="sleDeal==false?'log-deal-sle':'log-deal-lin'"></span>
                                <span class="login-pop-ctxt01">记住登录状态</span>
                            </p>
                            <p class="login-form-txt">
                                <span>忘记密码</span>
                                <font
                                    color="#ff6637"
                                    @click="getSign()"
                                >注册新用户></font>
                            </p>
                        </div>
                    </div>
                    <div class="popup-logWay">
                        <div class="logWay-tit">
                            <p>
                                <span>其他登录方式</span>
                            </p>
                        </div>
                        <div class="logWay-img">
                            <wxlogin
                                v-if="showWechat"
                                :appid="appid"
                                :scope="scope"
                                :redirect_uri="redirect_uri"
                            ></wxlogin>
                            <img
                                v-if="!showWechat"
                                src="../images/log_icon02.png"
                                @click="welogin();"
                                style="cursor:pointer;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="popup" v-show="qrcodeShow">
      <canvas ref="qrcode" id="qrcode"></canvas>
    </div>-->
        <!--弹窗-->
        <div
            class="popup"
            v-show="showVip"
        >
            <div
                class="active-popup animated fadeIn"
                :class="activeVipID=='2'?'vipWith':''"
            >
                <div
                    class="activeVip-popup"
                    v-show="activeVip"
                >
                    <div class="vip-popup-img">
                        <img src="../images/vip_bg01.png" />
                    </div>
                    <div
                        class="vip-popup-con"
                        v-show="!currentPhone"
                    >
                        <p class="vip-pop-ctxt01">请登录</p>
                        <p class="vip-pop-ctxt02">登录激活后继续答题</p>
                    </div>
                    <div
                        class="vip-popup-con"
                        v-show="currentPhone"
                    >
                        <p class="vip-pop-ctxt01">您不是vip</p>
                        <p class="vip-pop-ctxt02">购买vip后继续答题</p>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="closeActive()"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="onActiveBtn()"
                        >激活</div>
                    </div>
                </div>
                <div
                    class="activeVip-state"
                    v-show="activeVipBtn"
                >
                    <div class="state-popup-tit flex">
                        <p
                            :class="activeVipID=='1'?'state-pop-lin01':''"
                            @click="activeMode('1')"
                        >激活码激活</p>
                        <p
                            :class="activeVipID=='2'?'state-pop-lin02':''"
                            @click="activeMode('2')"
                        >套餐激活</p>
                    </div>
                    <div
                        class="state-popup-con"
                        v-if="activeVipID == '1'"
                    >
                        <div class="state-popup-ipt">
                            <input
                                type="text"
                                v-model="activeCode"
                                value
                                placeholder="请输入激活码"
                            />
                        </div>
                    </div>
                    <div
                        class="state-popup-combo"
                        v-if="activeVipID == '2'"
                    >
                        <div
                            v-for="(item,index) in packages"
                            :key="index"
                            @click="sleCombo(index)"
                            :class="comboImg==index?'combo-popup-lin':'combo-popup-con'"
                            v-show="!qrcodeShow"
                        >
                            <div class="combo-popup-tit flex flex-sb">
                                <p class="combo-ptit">
                                    <span>{{item.Title}}</span>
                                    <samp></samp>
                                </p>
                                <p class="combo-price">{{item.Money}}元/【PC:{{item.PCStr}}，APP:{{item.APPStr}}】</p>
                            </div>
                            <div class="combo-popup-txt">{{item.Remark}}</div>
                            <div
                                class="combo-img"
                                v-show="comboImg==index"
                            >
                                <img src="../images/vip_icon01.png" />
                            </div>
                        </div>

                        <div
                            v-show="qrcodeShow"
                            style="display: flex;flex-direction: column;align-items: center; margin-top: 20px;"
                        >
                            <canvas
                                ref="qrcode"
                                id="qrcode"
                                class="qr-con"
                            ></canvas>
                            <div>请扫码完成支付</div>
                        </div>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="closeActive()"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="activeVip2()"
                        >激活</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div
            class="popup"
            v-show="agreementVisible"
        >
            <div class="active-popup animated fadeIn">
                <div
                    class="activeVip-popup"
                    style="height:550px"
                >
                    <div class="vip-popup-con">
                        <p
                            class="vip-pop-ctxt01"
                            style="cursor:default"
                        >用户服务协议</p>
                        <template v-if="agreementContent">
                            <p
                                class="vip-pop-ctxt02"
                                style="padding:0 15px;height:395px;overflow-y:auto;cursor:default;text-align: left;text-indent: 2em;"
                                v-html="agreementContent"
                            ></p>
                        </template>
                        <template v-else>
                            <p
                                class="vip-pop-ctxt02"
                                style="padding:0 15px;height:395px;overflow-y:auto;cursor:default;"
                            >暂无内容</p>
                        </template>
                    </div>
                    <div
                        class="vip-popup-btn flex flex-jc"
                        style="margin-bottom:0px"
                    >
                        <div
                            class="popup-btn02"
                            @click="agreementVisible = false"
                        >确定</div>
                    </div>
                </div>

            </div>
        </div> -->
        <!-- 退出登录 -->
        <div
            class="popup"
            v-show="isLogout"
        >
            <div class="logout-pop animated fadeIn">
                <div class="login-logImg flex flex-jc">
                    <img src="../images/logo.png" />
                </div>
                <div class="logout-pop-txt">确定要退出登录吗?</div>
                <div class="flex logout-btn">
                    <div
                        class="logout-btn01"
                        @click="logout"
                    >确定</div>
                    <div
                        class="logout-btn02"
                        @click="logoutBtn"
                    >取消</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import {
    mapState
} from 'vuex';
import CryptoJS from 'crypto-js';
import wxlogin from 'vue-wxlogin';
import QRCode from 'qrcode';

// 头部
export default {
    name: 'jxheader',
    components: {
        wxlogin
    },
    data() {
        return {
            /* 退出登录 */
            isLogout: false,
            agreementContent: '',
            agreementVisible: false,
            showWechat: false,
            appid: 'wx46ee1d95d77bdbee',
            scope: 'snsapi_login',
            redirect_uri: 'https%3A%2F%2Fwww.meimeikeji.com/index.html',
            showSign: false,
            showLog: false,
            formData: {
                code: ''
            },
            show: true,
            count: '',
            timer: null,
            sleDeal: false,
            loginModel: {
                phone: '',
                pwd: ''
            },
            regModel: {
                phone: '',
                code: '',
                pwd1: '',
                pwd2: '',
                openId: ''
            },
            isVip: false,
            vipDate: '',
            currentUserName: '',
            currentPhone: '',
            regCode: '',
            activeVipBtn: false,
            activeVipID: '1',
            activeVip: true,
            comboImg: -1,
            showVip: false,
            activeType: 1, // 1:激活码,2:套餐
            packageId: 0, // 套餐id
            activeCode: '', // 激活码
            packages: [], // 套餐列表
            qrcodeShow: false,
            isMore: false// 是否查看更多
        };
    },
    created() {
        var code = this.GetQueryString('code');
        if (code) {
            this.wxlogin(code);
        }
        // console.log('code:', code);

        // :', wexinlogin);
        if (Cookies.get('tokenKey')) {
            this.getUserInfo();
        } else {
            var ciphertext = Cookies.get('currentUser');
            if (ciphertext) {
                var bytes = CryptoJS.AES.decrypt(ciphertext, 'qazxswedcvfr');
                var str = bytes.toString(CryptoJS.enc.Utf8);
                // console.log('str:', str);
                if (str) {
                    var obj = JSON.parse(str);
                    console.log(obj);

                    if (obj) {
                        // console.log('VIP:', obj);
                        this.currentUserName = obj.Name;
                        this.currentPhone = obj.Phone;
                        this.$store.dispatch('user/setUser', obj.Phone);
                        if (obj.Type === 2) {
                            this.isVip = true;
                        }
                        if (obj.ActivaPCTime && Number(new Date()) < Number(new Date(obj.ActivaPCTime))) {
                            this.vipDate = obj.ActivaPCTime.split('T')[0];
                            if (obj.ActivaPCDay === -1) {
                                this.vipDate = '永久会员';
                            }
                        } else {
                            this.isVip = false;
                        }
                        this.$store.dispatch('user/setIsVip', this.isVip);
                    }
                }
            }
        }
        if (this.$route.fullPath === '/activeVip' && !this.isVip) {
            this.showVip = true;
        }
        this.getPackage();
        this.getAgreement();
    },
    computed: {
        ...mapState(['token', 'car', 'user'])
    },
    watch: {
        $route(newVal) {
            console.log('$route -> newVal', newVal);

            if (newVal.fullPath === '/activeVip' && !this.isVip) {
                this.showVip = true;
            }
        }
    },
    methods: {
        // 查看更多车型
        showCar() {
            this.isMore = !this.isMore;
        },
        getUserInfo() {
            this.$api.getUserInfo().then(res => {
                const userObj = res.flag ? res.data : this.$util.getUser();
                if (userObj) {
                    if (res.flag) {
                        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), 'qazxswedcvfr').toString();
                        Cookies.set('currentUser', ciphertext);
                    }
                    this.currentUserName = userObj.Name;
                    this.currentPhone = userObj.Phone;
                    this.$store.dispatch('user/setUser', userObj.Phone);
                    if (userObj.Type === 2) {
                        this.isVip = true;
                        this.showVip = false;
                    }
                    if (userObj.ActivaPCTime && Number(new Date()) < Number(new Date(userObj.ActivaPCTime))) {
                        this.vipDate = userObj.ActivaPCTime.split('T')[0];
                        if (userObj.ActivaPCDay === -1) {
                            this.vipDate = '永久会员';
                        }
                    } else {
                        this.isVip = false;
                    }
                    this.$store.dispatch('user/setIsVip', this.isVip);
                }
            });
        },
        goAgreement() {
            this.showSign = false;
            this.showLog = false;
            this.showWechat = false;
            this.$store.dispatch('token/setShowLogin', false);
            this.$router.push('/agreement');
        },
        goPrivacy() {
            this.showSign = false;
            this.showLog = false;
            this.showWechat = false;
            this.$store.dispatch('token/setShowLogin', false);
            this.$router.push('/privacy');
        },
        getAgreement() {
            this.$api.getRegisterAgreement().then(res => {
                if (res.data.length > 0) {
                    this.agreementContent = res.data[0].Content;
                }
            });
        },
        wxlogin(code) {
            this.$api.wxLogin({ code }).then(res => {
                if (res.flag) {
                    var code = Number(res.code);
                    switch (code) {
                        case 1:
                            this.currentUserName = res.data.Name;
                            this.currentPhone = res.data.Phone;
                            if (res.data.Type === 2) {
                                this.isVip = true;
                            }
                            if (res.data.ActivaPCTime && Number(new Date()) < Number(new Date(res.data.ActivaPCTime))) {
                                this.vipDate = res.data.ActivaPCTime.split('T')[0];
                                if (res.data.ActivaPCDay === -1) {
                                    this.vipDate = '永久会员';
                                }
                            } else {
                                this.isVip = false;
                            }
                            this.$store.dispatch('user/setIsVip', this.isVip);

                            Cookies.set('tokenKey', res.token);

                            this.$store.dispatch('user/setUser', res.data.Phone);
                            this.$store.dispatch('token/setToken', res.token);

                            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), 'qazxswedcvfr').toString();
                            Cookies.set('currentUser', ciphertext);

                            this.$message({
                                message: '登录成功',
                                type: 'success'
                            });
                            this.showLog = false;
                            this.showWechat = false;
                            this.handleStateShowLogin();
                            break;
                        case 2:
                            this.$message({
                                message: '请注册',
                                type: 'success'
                            });
                            this.regModel.openId = res.data;
                            this.showSign = true;
                            break;
                    }
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                }
            });
        },
        GetQueryString(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]); return null;
        },
        /**
         * 选择车型
         */
        carTypeClick(id) {
            this.$store.dispatch('car/setSelectId', id);
            if (this.$route.fullPath !== '/home') {
                this.$router.push('/home');
            }
        },
        /**
         * 同意用户协议
         */
        deal() {
            this.sleDeal = !this.sleDeal;
        },
        /*
         * 获取验证码
         * */
        getCode(formData) {
            var TIME_COUNT = 59;
            if (!this.timer) {
                if (!this.regModel.phone) {
                    this.$message.error('请输入手机号码');
                    return false;
                }
                this.$api.getCode(this.regModel.phone).then(res => {
                    this.regCode = res.data;
                    this.regModel.code = res.data;
                });

                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        /*
         * 注册
         * */
        getSign() {
            this.showLog = false;
            this.showWechat = false;
            this.handleStateShowLogin();
            this.showSign = true;
        },
        /*
         * 登录
         * */
        getLog() {
            this.showSign = false;
            this.showLog = true;
        },
        /*
         * 关闭登录
         * */
        closeLog() {
            this.showLog = false;
            this.showWechat = false;
            if (!this.currentPhone && this.token.showLogin) {
                this.handleStateShowLogin();
                if (this.$route.fullPath !== '/home') {
                    this.$router.back(-1);
                }
            }
        },
        /*
         * 关闭注册
         * */
        closeSign() {
            this.showSign = !this.showSign;
        },
        /**
         * 登录
         */
        login() {
            if (!this.loginModel.phone) {
                this.$message.error('请输入用户名或手机号码');
                return false;
            }
            if (!this.loginModel.pwd) {
                this.$message.error('请输入密码');
                return false;
            }
            this.$api.login(this.loginModel).then(res => {
                this.currentUserName = res.data.Name;
                this.currentPhone = res.data.Phone;
                if (res.data.Type === 2) {
                    this.isVip = true;
                }
                if (res.data.ActivaPCTime && Number(new Date()) < Number(new Date(res.data.ActivaPCTime))) {
                    this.vipDate = res.data.ActivaPCTime.split('T')[0];
                    if (res.data.ActivaPCDay === -1) {
                        this.vipDate = '永久会员';
                    }
                } else {
                    this.isVip = false;
                }
                this.$store.dispatch('user/setIsVip', this.isVip);

                Cookies.set('tokenKey', res.token);

                this.$store.dispatch('user/setUser', res.data.Phone);
                this.$store.dispatch('token/setToken', res.token);

                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), 'qazxswedcvfr').toString();
                Cookies.set('currentUser', ciphertext);

                this.$message({
                    message: '登录成功',
                    type: 'success'
                });
                this.showLog = false;
                this.showWechat = false;
                this.handleStateShowLogin();
                window.location.reload();
            });
        },
        logout() {
            this.currentUserName = '';
            this.currentPhone = '';
            this.isVip = false;
            this.$store.dispatch('user/setIsVip', this.isVip);
            Cookies.remove('currentUser');
            Cookies.remove('tokenKey');
            this.$store.dispatch('user/deleteUser');
            this.$store.dispatch('token/deleteToken');

            window.location.href = '/index.html';
        },
        reg() {
            if (!this.regModel.phone) {
                this.$message.error('请输入手机号码');
                return false;
            }
            if (!this.regModel.code) {
                this.$message.error('请输入验证码');
                return false;
            }
            if (!this.regModel.pwd1) {
                this.$message.error('请输入密码');
                return false;
            }
            if (!this.regModel.pwd2) {
                this.$message.error('请输入确认密码');
                return false;
            }
            if (this.regModel.pwd2 !== this.regModel.pwd1) {
                this.$message.error('密码前后不一致');
                return false;
            }
            this.regModel.password = this.regModel.pwd1;
            if (!this.sleDeal) {
                this.$message.error('请勾选同意用户协议');
                return false;
            }
            // if (this.regModel.code !== this.regCode) {
            //     this.$message.error('验证码不正确');
            //     return false;
            // }

            this.$api.reg(this.regModel).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                for (const key in this.regModel) {
                    this.regModel[key] = '';
                }
                this.getLog();
            });
        },
        // 处理showlogin全局变量
        handleStateShowLogin() {
            this.$store.dispatch('token/setShowLogin', false);
        },
        /*
         * 激活Vip
         * */
        onActiveBtn() {
            if (this.currentPhone) {
                this.activeVipBtn = true;
                this.activeVip = false;
            }
        },
        /*
         * 取消激活
         * */
        closeActive() {
            this.showVip = !this.showVip;
            this.qrcodeShow = false;
            if (this.interval) {
                clearInterval(this.interval);
            }
            if (this.$route.fullPath === '/activeVip' && !this.isVip) {
                this.$router.back(-1);
            }
        },
        /*
         * 选择激活方式
         * */
        activeMode(id) {
            this.activeVipID = id;
            this.activeType = Number(id);
        },
        /*
         * 选择套餐
         * */
        sleCombo(index) {
            this.comboImg = index;
            this.packageId = this.packages[index].Id;
        },
        /**
         * 激活VIP
         * type:1:激活码,2:套餐
         */
        activeVip2() {
            switch (this.activeType) {
                case 1:
                    // console.log('aaa');
                    if (!this.activeCode) {
                        this.$message.error('请输入激活码');
                        return false;
                    }
                    this.$api.activeVipByCode(this.activeCode).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        if (res.data.Type === 2) {
                            this.isVip = true;
                        }
                        if (res.data.ActivaPCTime && Number(new Date()) < Number(new Date(res.data.ActivaPCTime))) {
                            this.vipDate = res.data.ActivaPCTime.split('T')[0];
                            if (res.data.ActivaPCDay === -1) {
                                this.vipDate = '永久会员';
                            }
                        } else {
                            this.isVip = false;
                        }
                        this.$store.dispatch('user/setIsVip', this.isVip);
                        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), 'qazxswedcvfr').toString();
                        Cookies.set('currentUser', ciphertext);
                        this.activeVip = false;
                        this.activeVipBtn = false;
                        this.showVip = false;
                    });
                    break;
                case 2:
                    if (this.packageId === 0) {
                        this.$message.error('请选择套餐');
                        return false;
                    }
                    this.$api.activeVipByPackage(this.packageId).then(res => {
                        var orderId = res.data.Id;

                        this.$api.scanPay(res.data.OrderNo).then(res2 => {
                            var obj = JSON.parse(res2.data);

                            QRCode.toCanvas(this.$refs.qrcode, obj.code_url, function (error) {
                                if (error) {
                                    console.error(error);
                                }
                                console.log('success!');
                            });
                            // this.showVip = false;
                            this.qrcodeShow = true;
                            this.checkOrder(orderId);
                        });

                        // this.$message({
                        //   message: res.msg,
                        //   type: 'success'
                        // });
                    });

                    // weixin://wxpay/bizpayurl?pr=S248USf

                    break;
            }
        },
        getPackage() {
            if (this.packages.length === 0) {
                this.$api.getPackage().then(res => {
                    this.packages = res.data;
                    // console.log('res:', res);
                });
            }
        },
        welogin() {
            //   console.log('微信登录');
            //   var obj = new wexinlogin.WxLogin({
            //     self_redirect: false,
            //     id: "login_container",
            //     appid: "wx072abc187fa0fbed",
            //     scope: "snsapi_login",
            //     redirect_uri: "/",
            //     state: "",
            //     style: "white",
            //     href: ""
            //   });
            this.showWechat = !this.showWechat;
        },
        openVip() {
            if (this.currentPhone) {
                this.showVip = true;
            } else {
                this.$message.error('请登录！');
            }
        },
        checkOrder(id) {
            this.interval = setInterval(() => {
                this.$api.checkOrder(id).then(res => {
                    if (res.flag) {
                        clearInterval(this.interval);
                        this.qrcodeShow = false;
                        this.showVip = false;

                        this.$message({
                            message: '支付成功',
                            type: 'success'
                        });
                        if (res.data.Type === 2) {
                            this.isVip = true;
                        }
                        if (res.data.ActivaPCTime && Number(new Date()) < Number(new Date(res.data.ActivaPCTime))) {
                            this.vipDate = res.data.ActivaPCTime.split('T')[0];
                            if (res.data.ActivaPCDay === -1) {
                                this.vipDate = '永久会员';
                            }
                        } else {
                            this.isVip = false;
                        }
                        this.$store.dispatch('user/setIsVip', this.isVip);
                        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), 'qazxswedcvfr').toString();
                        Cookies.set('currentUser', ciphertext);
                        this.activeVip = false;
                        this.activeVipBtn = false;
                        this.showVip = false;
                    }
                });
            }, 3000);
        },
        /* 退出登录 */
        logoutBtn() {
            this.isLogout = !this.isLogout;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variable.scss';
@import '../styles/public.scss';
@import '../styles/login.scss';
@import '../styles/vipPopup.scss';

.user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ptText {
    width: 184px;
}
</style>
