<template>
    <div class="jxFooter">
        <div class="jxFooter-con">
            <div class="jxFooter-logo flex flex-jc"><img src="../images/logo.png" /></div>
            <div class="jxFooter-txt">备案/许可证编号为：辽ICP备2020011290号</div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../styles/variable.scss';
@import '../styles/public.scss';
</style>
<script>
export default {
    name: 'jxFooter',
    data() {
        return {};
    },
    components: {}
};
</script>
